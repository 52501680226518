<template>
  <div class="box">
    <div class="box-body">
      <form
        @submit.prevent="submit"
        v-promise-btn="{ action: 'submit' }"
        class="container"
      >
        <div class="row">
          <div class="col d-flex align-items-baseline justify-content-between">
            <h2 v-if="mode == 'create'">สร้างแนวคิดโครงการ</h2>
            <h2 v-else>แก้ไขแนวคิดโครงการ</h2>
            <div>
              <input
                type="checkbox"
                id="project_editor_continuous"
                name="ongoing"
                v-model="projectEdit.ongoing"
              />&nbsp;<label for="project_editor_continuous"
            >โครงการต่อเนื่อง</label
            >
            </div>
          </div>
        </div>
        <div class="d-flex mb-3 align-items-center">
          <label class="mr-4" for="project_editor_name">
            <span class="font-weight-bold">ชื่อโครงการ:</span>
          </label>
          <div class="w-auto flex-fill">
            <input
              class="form-control"
              type="text"
              id="project_editor_name"
              v-model="projectEdit.name"
            />
            <div class="error text-danger" v-if="projectEdit.$v.name.$dirty">
              <div v-if="!projectEdit.$v.name.required">โปรดระบุ</div>
              <div v-if="!projectEdit.$v.name.maxLength">
                เกินจำนวนตัวอักษรที่กำหนด
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 font-weight-bold">
          <div class="col-md-2 text-center">ปีที่ดำเนินงาน</div>
          <div class="col-md-5 text-center">งบที่เสนอขอ (บาท)</div>
          <div class="col-md-5 text-center">งบที่ได้รับ (บาท)</div>
        </div>
        <div
          class="row mb-3"
          v-for="(budget, index) in projectEdit.$v.project_budgets.$each.$iter"
          :key="index"
        >
          <div class="col-2">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              :value="budget.year.$model"
            />
          </div>
          <div class="col-5">
            <div class="w-auto flex-fill">
              <vue-autonumeric
                id="project_editor_budget_expected"
                class="form-control"
                v-bind:disabled="isEditPreviousYear"
                v-model="budget.budget_expected.$model"
                :options="$config.autoNumericOption(0)"
              ></vue-autonumeric>
            </div>
          </div>
          <div class="col-5">
            <div class="w-auto flex-fill">
              <vue-autonumeric
                id="project_editor_budget_actual"
                class="form-control"
                v-bind:disabled="!isEditPreviousYear"
                v-model="budget.budget_actual.$model"
                :options="$config.autoNumericOption(0)"
              ></vue-autonumeric>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-baseline">
            <label for="project_editor_budget_sources" class="mr-4">
              <span class="font-weight-bold">แหล่งงบประมาณ</span>
            </label>
            <div class="form-group">
              <button
                class="btn btn-red"
                type="button"
                @click="addBudgetSource"
              >
                <eva-icon name="plus-circle-outline"></eva-icon>
                เพิ่มแหล่งงบ
              </button>
            </div>
          </div>
          <div class="col-12">
            <div
              class="form-group row"
              v-for="(source, index) in projectEdit.$v.budget_sources.$each
                .$iter"
              :key="index"
            >
              <div class="col-12">
                <div class="row">
                  <div class="col-1 mt-1">
                    <label class="col-form-label font-weight-bold"
                    >{{ parseInt(index) + 1 }}.</label
                    >
                  </div>
                  <div class="col-4">
                    <select
                      @change="updateSource(index)"
                      name="budget_sources"
                      v-model="source.type.$model"
                      id="budget_sources"
                      class="form-control flex-fill"
                    >
                      <option
                        v-for="(
                          choosingSource, choosingSourceIndex
                        ) in budgetSources"
                        :key="choosingSource.id"
                        v-bind:value="choosingSource.id"
                        v-bind:selected="choosingSourceIndex === 0"
                      >
                        {{ choosingSource.title }}
                      </option>
                    </select>
                    <div class="error text-danger" v-if="source.type.$dirty">
                      <div v-if="!source.type.required">โปรดระบุ</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <!--show when type = 'อื่นๆ'-->
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="source.other.$model"
                        v-show="source.type.$model == 6"
                        placeholder="ระบุแหล่งงบอื่นๆ"
                      />
                      <div
                        class="error text-danger"
                        v-if="source.other.$dirty && source.type.$model == 6"
                      >
                        <div v-if="!source.other.required">โปรดระบุ</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <button
                      type="button"
                      @click="removeSource(index)"
                      class="btn btn-outline-red"
                    >
                      ลบ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <label
              for="project_editor_target_group"
              class="mr-4 font-weight-bold"
            >กลุ่มเป้าหมาย</label
            >
            <div class="w-auto flex-fill">
              <input
                name="main_activity"
                id="project_editor_target_group"
                v-model="projectEdit.target_group"
                class="form-control"
                type="text"
                placeholder="เช่น โรงแรม, อุตสาหกรรมอาหารกระป๋อง, วิสาหกิจชุมชนแปรรูปอาหาร ฯลฯ"
              />
              <div
                class="error text-danger"
                v-if="projectEdit.$v.target_group.$dirty"
              >
                <div v-if="!projectEdit.$v.target_group.maxLength">
                  เกินจำนวนตัวอักษรที่กำหนด
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 d-flex align-items-baseline">
            <label for="project_editor_activities" class="mr-4 font-weight-bold"
            >กิจกรรมหลัก</label
            >
            <div class="form-group">
              <button class="btn btn-red" type="button" @click="addActivity">
                <eva-icon name="plus-circle-outline"></eva-icon>
                เพิ่มกิจกรรม
              </button>
            </div>
          </div>
          <div class="col-12">
            <div
              class="form-group row"
              v-for="(activity, index) in projectEdit.$v.project_activities
                .$each.$iter"
              :key="index"
            >
              <label class="col-form-label col-1 font-weight-bold"
              >{{ parseInt(index) + 1 }}.</label
              >
              <div class="col-10">
                <textarea
                  class="form-control"
                  v-model="activity.content.$model"
                ></textarea>
                <div class="error text-danger" v-if="activity.content.$dirty">
                  <div v-if="!activity.content.required">โปรดระบุ</div>
                  <div v-if="!activity.content.maxLength">
                    เกินจำนวนตัวอักษรที่กำหนด
                  </div>
                </div>
              </div>
              <div class="col-1">
                <button
                  type="button"
                  @click="removeActivity(index)"
                  class="btn btn-outline-red"
                >
                  ลบ
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="mr-4 mb-2 font-weight-bold">
            ผลลัพธ์เชิงพลังงาน (ผลลัพธ์ของเป้าประสงค์)
          </div>
          <div
            class="row mb-4"
            v-for="projectTargetIndicator in projectEdit.$v.project_target_indicators.$each.$iter"
            :key="projectTargetIndicator.$model.target_indicator_id"
          >
            <div class="col-12 d-flex align-items-end">
              <div class="mr-4 mb-2">
                {{ indicator(project, projectTargetIndicator) }}
              </div>
              <div class="flex-fill mr-4 w-10">
                <div class="">
                  ผลลัพธ์ของโครงการนี้ที่คาดหวัง
                  <span
                    class="error text-danger font-weight-normal"
                    v-if="
                      !isEditPreviousYear &&
                      projectTargetIndicator.indicator_value_expected.$dirty
                    "
                  >
                    <span
                      v-if="
                        !projectTargetIndicator.indicator_value_expected
                          .required
                      "
                    >
                      โปรดระบุ
                    </span>
                  </span>
                </div>
                <input
                  name="indicator_value_expected"
                  type="number"
                  class="form-control"
                  v-model="
                    projectTargetIndicator.$model.indicator_value_expected
                  "
                  v-bind:disabled="isEditPreviousYear"
                  placeholder="ผลลัพธ์้ที่คาดหวัง"
                />
              </div>
              <div class="flex-fill mr-4 unit-width">
                <div class="">
                  หน่วย
                  <small
                    class="error text-danger font-weight-normal"
                    v-if="
                      !isEditPreviousYear &&
                      projectTargetIndicator.indicator_unit_expected.$dirty
                    "
                  >
                    <small
                      v-if="
                        !projectTargetIndicator.indicator_unit_expected.required
                      "
                    >
                      โปรดระบุ
                    </small>
                    <small
                      v-if="
                        !projectTargetIndicator.indicator_unit_expected
                          .maxLength
                      "
                    >
                      เกินจำนวนตัวอักษรที่กำหนด
                    </small>
                  </small>
                </div>
                <input
                  name="indicator_unit_expected"
                  type="text"
                  class="form-control"
                  v-model="
                    projectTargetIndicator.$model.indicator_unit_expected
                  "
                  v-bind:disabled="isEditPreviousYear"
                  placeholder="หน่วย"
                />
              </div>
              <div class="flex-fill">
                <div class="">
                  ผลลัพธ์ของโครงการนี้ที่เกิดขึ้นจริง
                  <span
                    class="error text-danger font-weight-normal"
                    v-if="
                      isEditPreviousYear &&
                      projectTargetIndicator.indicator_value_result.$dirty
                    "
                  >
                    <span
                      v-if="
                        !projectTargetIndicator.indicator_value_result.required
                      "
                    >
                      โปรดระบุ
                    </span>
                  </span>
                </div>
                <input
                  name="indicator_value_result"
                  type="number"
                  class="form-control"
                  v-model="projectTargetIndicator.$model.indicator_value_result"
                  v-bind:disabled="!isEditPreviousYear"
                  :placeholder="
                    isEditPreviousYear
                      ? 'ผลลัพธ์ของโครงการนี้ที่เกิดขึ้นจริง'
                      : 'ผลลัพธ์ของโครงการนี้ที่เกิดขึ้นจริง (กรอกในปีถัดไป)'
                  "
                />
              </div>
            </div>
            <div class="col-12 bg-gray-100 h-20 mt-2 py-2">
              <div class="text-center">
                <span class="font-weight-bold text-underline">กรอกข้อมูลส่วนนี้ด้วยหากทราบ</span>
              </div>
              <div class="w-full d-flex align-items-end">
                <div class="mr-4 mb-2">
                  {{ indicator(project, projectTargetIndicator) }}
                </div>
                <div class="flex-fill mr-4 w-10">
                  <div class="">เทียบผลลัพธ์เชิงพลังงาน(toe)</div>
                  <input
                    name="indicator_value_expected"
                    type="number"
                    class="form-control"
                    v-model="
                      projectTargetIndicator.$model.indicator_toe_value_expected
                    "
                    v-bind:disabled="isEditPreviousYear"
                    placeholder="เทียบผลลัพธ์เชิงพลังงาน"
                  />
                </div>
                <div class="flex-fill mr-4 unit-width">
                  <div class="">หน่วย</div>
                  <input
                    name="indicator_unit_expected"
                    type="text"
                    class="form-control"
                    readonly
                    value="toe"
                  />
                </div>
                <div class="flex-fill">
                  <div class="">ผลลัพธ์ของโครงการนี้ที่เกิดขึ้นจริง(toe)</div>
                  <input
                    name="indicator_value_result"
                    type="number"
                    class="form-control"
                    v-model="
                      projectTargetIndicator.$model.indicator_toe_value_result
                    "
                    v-bind:disabled="!isEditPreviousYear"
                    :placeholder="
                      isEditPreviousYear
                        ? 'ผลลัพธ์ของโครงการนี้ที่เกิดขึ้นจริง'
                        : 'ผลลัพธ์ของโครงการนี้ที่เกิดขึ้นจริง (กรอกในปีถัดไป)'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 align-items-end">
            <div class="mr-4 mb-2 font-weight-bold">ผลลัพธ์สำคัญอื่นๆ</div>
          </div>
          <div
            class="col-12"
            v-for="(projectTargetResult, index) in projectEdit.$v.target_results
              .$each.$iter"
            :key="index"
          >
            <div class="row">
              <div class="col-4 align-items-end" v-if="isEditPreviousYear">
                &nbsp;
                <div>
                  {{ projectTargetResult.$model.category_title }} -
                  {{ projectTargetResult.$model.name }} ({{
                    projectTargetResult.$model.unit
                  }})
                </div>
              </div>
              <div class="col-4 align-items-end" v-else>
                &nbsp;
                <div>เลือกผลลัพธ์สำคัญ</div>
                <select
                  name="target_result"
                  id="target_result"
                  v-model="projectTargetResult.id.$model"
                  class="form-control"
                >
                  <option
                    v-for="targetResult in target.target_results"
                    :key="targetResult.id"
                    v-bind:value="targetResult.id"
                  >
                    {{ targetResult.category_title }} -
                    {{ targetResult.name }} ({{ targetResult.unit }})
                  </option>
                </select>
              </div>
              <div class="col-4">
                <div class="flex-fill mr-4">
                  <div class="">ค่าเป้าหมาย</div>
                  <vue-autonumeric
                    class="form-control"
                    name="expected_value"
                    v-bind:disabled="isEditPreviousYear"
                    v-model="projectTargetResult.$model.expected_value"
                    :options="$config.autoNumericOption(3)"
                    placeholder="ค่าเป้าหมาย"
                  ></vue-autonumeric>
                  <span
                    class="error text-danger font-weight-normal"
                    v-if="
                      !isEditPreviousYear &&
                      projectTargetResult.expected_value.$dirty
                    "
                  >
                    <span v-if="!projectTargetResult.expected_value.required">
                      โปรดระบุ
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="flex-fill">
                  <div class="">ผลลัพธ์จริง</div>
                  <vue-autonumeric
                    class="form-control"
                    name="result_value"
                    v-bind:disabled="!isEditPreviousYear"
                    v-model="projectTargetResult.result_value.$model"
                    :options="$config.autoNumericOption(3)"
                    placeholder="ผลลัพธ์จริง"
                  ></vue-autonumeric>
                  <div
                    class="error text-danger font-weight-normal"
                    v-if="
                      isEditPreviousYear &&
                      projectTargetResult.result_value.$dirty
                    "
                  >
                    <div v-if="!projectTargetResult.result_value.required">
                      โปรดระบุ
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="isEditPreviousYear">
          <div class="col-12 d-flex flex-column">
            <label for="project_editor_actual_target_result">
              <span class="font-weight-bold">ผลลัพธ์จริงอื่นๆ</span>
              (หากไม่มีไม่ต้องกรอก)
            </label>
            <textarea
              name="actual_target_result"
              id="project_editor_actual_target_result"
              cols="30"
              rows="2"
              class="form-control"
              v-model="projectEdit.actual_target_result"
            ></textarea>
            <div
              class="error text-danger"
              v-if="
                isEditPreviousYear && projectEdit.$v.actual_target_result.$dirty
              "
            >
              <div v-if="!projectEdit.$v.actual_target_result.maxLength">
                เกินจำนวนตัวอักษรที่กำหนด
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 d-flex flex-column" v-if="isEditPreviousYear">
            <label for="project_editor_threat">
              <span class="font-weight-bold">อุปสรรคสำคัญ</span
              >(หากไม่มีไม่ต้องกรอก)</label
            >
            <textarea
              name="threat"
              id="project_editor_threat"
              cols="30"
              rows="2"
              class="form-control"
              v-model="projectEdit.threat"
            ></textarea>
            <div
              class="error text-danger"
              v-if="isEditPreviousYear && projectEdit.$v.threat.$dirty"
            >
              <div v-if="!projectEdit.$v.threat.maxLength">
                เกินจำนวนตัวอักษรที่กำหนด
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            d-flex
            flex-row-reverse
            align-items-center
            justify-content-between
          "
        >
          <button class="btn btn-action" type="submit">
            {{ $t('common.save') }}
          </button>
          <button
            class="btn btn-outline-red"
            type="button"
            v-if="isEdit"
            @click="deleteProjectAlert"
          >
            ลบโครงการนี้
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from '../modules/form/form';
import {
  required,
  requiredIf,
  sameAs,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {nth, reduce, cloneDeep, get, range, find} from 'lodash';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

const defaultValue = {
  target_strategy_id: null,
  name: '',
  ongoing: false,
  project_budgets: [],
  budget_sources: [],
  target_group: '',
  project_activities: [],
  project_target_indicators: [],
  // indicator_value_expected: null,
  // indicator_toe_value_expected: null,
  // indicator_value_result: null,
  // indicator_toe_value_result: null,
  // indicator_unit_expected: null,
  target_results: [],
  threat: '',
  actual_target_result: '',
};
const editRules = {
  name: {
    required,
    maxLength: maxLength(255),
  },
  project_budgets: {
    $each: {
      year: {
        required,
      },
      budget_expected: {},
      budget_actual: {},
    },
  },
  budget_sources: {
    $each: {
      type: {
        required,
      },
      other: {
        required: requiredIf((source) => source.type == 6),
        maxLength: maxLength(255),
      },
    },
  },
  target_group: {
    maxLength: maxLength(255),
  },
  project_activities: {
    $each: {
      content: {
        required,
        maxLength: maxLength(65000),
      },
    },
  },
  project_target_indicators: {
    $each: {
      indicator_value_result: {
        required,
      },
      indicator_value_expected: {},
      indicator_toe_value_result: {},
      indicator_toe_value_expected: {},
    },
  },
  target_results: {
    $each: {
      result_value: {
        required,
      },
    },
  },
  actual_target_result: {
    maxLength: maxLength(65000),
  },
  threat: {
    maxLength: maxLength(16000000),
  },
};
const createRules = {
  name: {
    required,
    maxLength: maxLength(255),
  },
  project_budgets: {
    $each: {
      year: {
        required,
      },
      budget_expected: {},
      budget_actual: {},
    },
  },
  target_group: {
    maxLength: maxLength(255),
  },
  project_activities: {
    $each: {
      content: {
        required,
        maxLength: maxLength(65000),
      },
    },
  },
  budget_sources: {
    $each: {
      type: {
        required,
      },
      other: {
        required: requiredIf((source) => source.type == 6),
        maxLength: maxLength(255),
      },
    },
  },
  project_target_indicators: {
    $each: {
      indicator_value_expected: {required},
      indicator_toe_value_expected: {},
      indicator_unit_expected: {
        required,
        maxLength: maxLength(20),
      },
    },
  },
  target_results: {
    $each: {
      id: {
        required,
      },
      expected_value: {
        required,
      },
      result_value: {
        required,
      },
    },
  },
};

export default {
  name: 'project-editor',
  props: {
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'create', // 'create' or 'edit'
    },
    project: {
      type: Object,
      required: true,
    },
    targetStrategyId: {
      // for create project
      type: Number,
      required: false,
    },
    // targetResults: { // for create project
    //   type: Array,
    //   required: false
    // },
    target: {
      // for create project
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      // projectEdit: null,
      projectEdit: null,
    };
  },
  computed: {
    ...mapGetters(['latestNationalPlan']),
    ...mapState('peno', ['targetEconomies', 'budgetSources']),
    isEditPreviousYear() {
      return this.mode == 'edit_previous';
    },
    isEdit() {
      return this.mode == 'edit';
    },
    isCreate() {
      return this.mode == 'create';
    },
    budgetYears() {
      return range(
        this.latestNationalPlan.start_budget_year,
        this.latestNationalPlan.end_budget_year + 1
      );
    },
  },
  created() {
    if (this.isEditPreviousYear) {
      this.projectEdit = new Form({
        data() {
          return JSON.parse(JSON.stringify(defaultValue)); // prevent values retention for subsequent modals
        },
        validations: editRules,
      });
      this.setFormData(this.project, true);
    } else if (this.isEdit) {
      this.projectEdit = new Form({
        data() {
          return JSON.parse(JSON.stringify(defaultValue));
        },
        validations: createRules,
      });
      this.setFormData(this.project);
    } else {
      this.projectEdit = new Form({
        data() {
          return JSON.parse(JSON.stringify(defaultValue));
        },
        validations: createRules,
      });
      this.setCreateFormData();
    }
  },
  methods: {
    updateSource(index) {
      if (this.projectEdit.budget_sources[index].type === 6) {
        this.projectEdit.budget_sources[index].other = '';
      } else {
        this.projectEdit.budget_sources[index].other = null;
      }
    },
    addActivity() {
      this.projectEdit.project_activities.push({
        content: '',
      });
    },
    addBudgetSource() {
      this.projectEdit.budget_sources.push({
        type: '',
        other: '',
      });
    },
    removeActivity(index) {
      this.projectEdit.project_activities.splice(index, 1);
    },
    removeSource(index) {
      this.projectEdit.budget_sources.splice(index, 1);
    },
    setFormData(project, previous = false) {
      this.projectEdit.target_strategy_id = project.target_strategy_id;
      this.projectEdit.name = project.name;
      this.projectEdit.ongoing = project.ongoing;
      if (previous) {
        this.projectEdit.project_budgets =
          cloneDeep(project.project_budgets) || [];
      } else {
        this.projectEdit.project_budgets = this.budgetYears.map((item) => {
          const budget = find(project.project_budgets, ['year', item]);
          if (!budget) {
            return {
              year: item,
              budget_expected: '',
              budget_actual: '',
            };
          }
          return {
            year: budget.year,
            budget_expected: budget.budget_expected,
            budget_actual: budget.budget_actual,
          };
        });
      }

      this.projectEdit.project_target_indicators =
        project.project_target_indicators;

      this.projectEdit.target_group = project.target_group;
      this.projectEdit.project_activities =
        cloneDeep(project.project_activities) || [];
      this.projectEdit.budget_sources = cloneDeep(project.budget_sources) || [];
      /* this.projectEdit.indicator_value =
        project['target-results'].data[0].target.data.indicator_value; */
      // this.projectEdit.indicator_value_expected = project.indicator_value_expected;
      // this.projectEdit.indicator_toe_value_expected = project.indicator_toe_value_expected;
      // this.projectEdit.indicator_unit_expected = project.indicator_unit_expected;
      // this.projectEdit.indicator_value_result = project.indicator_value_result;
      // this.projectEdit.indicator_toe_value_result = project.indicator_toe_value_result;
      this.projectEdit.target_results =
        cloneDeep(project['target-results'].data) || [];
      this.projectEdit.threat = project.threat;
      this.projectEdit.actual_target_result = project.actual_target_result;
    },
    setCreateFormData() {
      this.projectEdit.project_budgets = this.budgetYears.map((item) => {
        return {
          year: item,
          budget_expected: '',
          budget_actual: '',
        };
      });

      this.projectEdit.project_target_indicators =
        this.target.target_indicators.map((targetIndicator) => {
          return {
            id: null,
            target_indicator_id: targetIndicator.id,
            indicator_value_expected: null,
            indicator_toe_value_expected: null,
            indicator_value_result: null,
            indicator_toe_value_result: null,
            indicator_unit_expected: null,
          };
        });

      this.projectEdit.target_strategy_id = this.targetStrategyId;
      this.projectEdit.target_results = [];
      if (this.target.target_results.length > 0) {
        this.projectEdit.target_results.push({
          id: this.target.target_results[0].id,
          expected_value: 0,
        });
      }
    },
    indicator(project, project_target_indicator) {
      let targetType = null;
      let indicatorName = '';
      let targetIndicatorValue = 0;

      const targetIndicator = this.target.target_indicators.find(
        (item) =>
          item.id === project_target_indicator.$model.target_indicator_id
      );

      // if (this.isCreate) {
      //   targetType = this.target.type;
      //   indicatorName = this.target.indicator_name;
      //   targetIndicatorValue = this.target.indicator_value;
      // }
      // if (project && this.isEdit) {
      //   targetType = project.target.type;
      //   indicatorName = project.indicator_name;
      //   targetIndicatorValue = project.target.indicator_value;
      // }
      targetType = this.target.type;
      indicatorName = targetIndicator.name;
      targetIndicatorValue = targetIndicator.value;

      const indicatorKtoeExist = targetIndicatorValue > 0 ? '' : '';
      switch (targetType) {
        case 1:
          return indicatorName + ` RE ${indicatorKtoeExist}`;
        case 2:
          return indicatorName + ` EE ${indicatorKtoeExist}`;
        case 3:
          if (this.isEdit && project['target-results'].data.length > 0) {
            return project['target-results'].data[0].target.data.type_other;
          }
          return this.target.type_other;
        default:
          return '-';
      }
    },
    onInput(index, name, value) {
      const data = this.value;
      data[index][name] = value;
      this.$emit('input', data);
      this.$emit('change', data);
    },
    submit() {
      let url = `/api/projects`;
      let requestType = 'post';
      if (this.mode == 'edit') {
        url = `/api/projects/${this.project.id}`;
        requestType = 'patch';
      }
      if (this.mode == 'edit_previous') {
        url = `/api/projects/${this.project.id}/previous`;
        requestType = 'patch';
      }
      return this.projectEdit
        .submit(requestType, url)
        .then((response) => {
          if (this.isCreate) {
            this.$emit('onSaved', response.data);
          } else {
            this.$emit('onUpdated', response.data);
          }
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    deleteProjectAlert() {
      this.$vDialog.alert(
        'โครงการนี้จะถูกลบ',
        () => {
          window.axios
            .delete(`/api/projects/${this.project.id}`)
            .then((response) => {
              this.$emit('onDeleted', this.project);
              this.$modal.hide(this.name);
            })
            .catch((error) => {
              console.error(error);
              this.$toasted.global.display_error({
                message: error,
              });
            });
        },
        {
          messageType: 'confirm',
          title: 'ยืนยัน',
          iconClassName: '',
          isCaution: true,
          cancelCallback: () => {
          },
        }
      );
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}

.unit-width {
  width: 30px;
}
</style>
